<template>
     <section class="gao-de-map">
          <!--    地图      -->
          <div id="container"></div>

          <!--    输入提示后查询      -->
          <div class="map-tools">
               <el-autocomplete
                       v-if="showAutocomplete"
                       class="inline-input"
                       v-model="keywords"
                       :fetch-suggestions="handleAutocomplete"
                       placeholder="请输入关键字"
                       value-key="name"
                       :trigger-on-focus="false"
                       @select="handlePlaceSearch"
                       @clear="handleAutocomplete"
                       clearable
               ></el-autocomplete>
          </div>
     </section>
</template>

<script>
    import AMapLoader from "@amap/amap-jsapi-loader";

    const community = require("@/assets/images/community.png");

    export default {
        props: {
            mapTitle: {
                type: String,
                default: "",
            },
            // 地图中心点
            center: {
                type: Array,
                default: () => [],
            },
            // 点标记
            markers: {
                type: Array,
                default: () => [],
            },
            // 开启输入提示后查询
            showAutocomplete: {
                type: Boolean,
                default: false
            },
            markerContent: Function
        },
        name: "GaoDeMap",
        data() {
            return {
                keywords: null
            };
        },
        watch: {
            center: {
                handler() {
                    this.$nextTick(() => {
                        // 设置地图中心点
                        if (this.center.length === 2) this.map.setCenter(this.center);
                    });
                },
                deep: true
            },
            markers: {
                handler() {
                    this.$nextTick(() => {
                        this.createMarkerCluster();
                    });
                },
                deep: true
            }
        },
        methods: {
            handleAutocomplete(keywords, cb) {
                // 输入提示
                this.AMap.plugin("AMap.Autocomplete", () => {
                    // 实例化Autocomplete
                    const autoComplete = new this.AMap.Autocomplete({
                        city: "全国",
                    });
                    autoComplete.search(this.keywords, (status, result) => {
                        // 搜索成功时，result即是对应的匹配数据
                        const tips = status === "complete" ? result.tips : [];
                        cb && cb(tips || []);
                    });
                });
                this.$emit("update:inputChange", keywords);
            },
            handlePlaceSearch(item) {
                // 地址搜索
                const { location } = item;
                const lnglat = [location.lng, location.lat];
                this.createSingleMarker(lnglat);
                this.map.setZoomAndCenter(15, lnglat);
                this.$emit("on-autocomplete", item);
            },
            async createSingleMarker(lnglat = []) {
                // 生成单个标记点
                await this.clearMapMarker();
                this.createMapMarker({ lnglat });
            },
            async createMultipleMarkers() {
                // 生成多个标记点
                await this.clearMapMarker();
                for (const item of this.markers) {
                    const { longtitude, latitude } = item;
                    if (!longtitude || !latitude) continue;
                    const lnglat = [longtitude, latitude];
                    this.createMapMarker({ lnglat });
                }
            },
            async createMarkerCluster() {
                await this.clearMapMarker();
                let markers = [];
                for (const item of this.markers) {
                    const { longtitude, latitude } = item;
                    if (!longtitude || !latitude) continue;
                    const lnglat = [longtitude, latitude];
                    const weight = 8;
                    markers.push({ ...item, lnglat, weight });
                }
                this.AMap.plugin("AMap.MarkerCluster", () => {
                    const markerCluster = new this.AMap.MarkerCluster(this.map, markers, {
                        renderClusterMarker: this.createRenderClusterMarker,
                        renderMarker: ({ marker, data }) => {
                            marker.setIcon(this.createMarkerIcon());
                            marker.setLabel({
                                content: `<div class='info'>${ data[0].name }</div>`,
                                direction: "bottom"
                            });
                            marker.on("click", () => {
                                this.createInfoWindow(data[0]);
                            });
                        }
                    });
                    markerCluster.on("click", (e) => {
                        let curZoom = this.map.getZoom();
                        if (curZoom < 16) {
                            curZoom += 1;
                        }
                        this.map.setZoomAndCenter(curZoom, e.lnglat);
                    });
                });
            },
            createMarkerIcon() {
                return new this.AMap.Icon({
                    size: new this.AMap.Size(83.4, 67.4),
                    image: community,
                    imageSize: new this.AMap.Size(83.4, 67.4)
                });
            },
            createInfoWindow(data) {
                const { longtitude, latitude } = data;
                const position = [longtitude, latitude];
                const infoWindow = new this.AMap.InfoWindow({
                    position,
                    offset: new this.AMap.Pixel(30, -50),
                    content: this.markerContent(data),
                });
                infoWindow.open(this.map);
            },
            createMapMarker({ lnglat = [] }) {
                // 生成点标记
                new this.AMap.Marker({
                    position: lnglat,
                    map: this.map,
                });
            },
            createRenderClusterMarker(context) {
                const count = this.markers.length;
                const factor = Math.pow(context.count / count, 1 / 18);
                const div = document.createElement("div");
                const Hue = 180 - factor * 180;
                const bgColor = "hsla(" + Hue + ",100%,40%,0.7)";
                const fontColor = "hsla(" + Hue + ",100%,90%,1)";
                const borderColor = "hsla(" + Hue + ",100%,40%,1)";
                const shadowColor = "hsla(" + Hue + ",100%,90%,1)";
                div.style.backgroundColor = bgColor;
                const size = Math.round(30 + Math.pow(context.count / count, 1 / 5) * 20);
                div.style.width = div.style.height = size + "px";
                div.style.border = "solid 1px " + borderColor;
                div.style.borderRadius = size / 2 + "px";
                div.style.boxShadow = "0 0 5px " + shadowColor;
                div.innerHTML = context.count;
                div.style.lineHeight = size + "px";
                div.style.color = fontColor;
                div.style.fontSize = "14px";
                div.style.textAlign = "center";
                context.marker.setOffset(new this.AMap.Pixel(-size / 2, -size / 2));
                context.marker.setContent(div);
            },
            async clearMapMarker() {
                // 清空点标记
                const { map } = await this.initGaoDeMap();
                map.clearMap();
            },
            initGaoDeMap() {
                // 初始化完成进行地图初始化
                return new Promise((resolve, reject) => {
                    if (this.map && this.AMap) return resolve({ map: this.map, AMap: this.AMap });
                    AMapLoader.load({
                        key: "1428638687b08afb032d2ed539051647",
                        version: "2.0",
                        plugins: ["AMap.PlaceSearch", "AMap.AutoComplete", "AMap.MarkerCluster"],
                    }).then((AMap) => {
                        // 默认中心点是当前ip的定位
                        this.AMap = AMap;
                        const map = new AMap.Map("container", {
                            zoom: 11,
                            viewMode: "3D",
                            resizeEnable: true
                        });
                        this.map = map;
                        return resolve({ map, AMap });
                    }).catch(e => {
                        console.err("初始化完成进行地图初始化", e);
                        reject(e);
                    });
                });
            },
        },
        mounted() {
            // DOM初始化完成进行地图初始化
            this.initGaoDeMap();
        }
    };
</script>

<style lang="scss" scoped>
     .gao-de-map {
          width: 100%;
          height: 100%;
          position: relative;

          #container {
               width: 100%;
               height: 100%;
               position: absolute;
               top: 0;
               left: 0;
          }

          .map-tools {
               position: absolute;
               top: VH(20px);
               right: VW(20px);
          }

          /deep/ .amap-markers {
               .amap-marker-label {
                    padding: VH(10px) VW(15px);
                    border: none;
                    border-radius: 5px;
                    box-shadow: 0 6px 12px rgba(0, 45, 170, 0.16);
               }
          }
     }
</style>
