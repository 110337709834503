import request from '@http';

// 获取小区列表
export function getBookingListApi(params) {
    return request({
        method: 'GET',
        url: 'api/gzf/build/make',
        params,
    })
}

export function getBookingPutStatus(params) {
    return request({
        method: 'GET',
        url: 'api/gzf/build/make/put',
        params,
    })
}