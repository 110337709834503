<template>
     <section class="house-distribution">
          <gao-de-map class="gao-de-map" ref="gaoDeMapRef" :markers="houseList" :marker-content="createMarkerContent"
                      :center="[108.265887,22.879070]"/>
     </section>
</template>

<script>
    import GaoDeMap from "@/components/GaoDeMap"; //引入已封装高德地图组件
    import { getBaseInfoCommunityApi } from "@/api/house-distribution";

    export default {
        name: "house-distribution",
        data() {
            return {
                houseList: [],
            };
        },
        components: { GaoDeMap },
        methods: {
            //获取小区数据
            async getBaseInfoCommunity() {
                try {
                    // 获取小区列表
                    const { list = [] } = await getBaseInfoCommunityApi();
                    this.houseList = list;
                } catch (e) {
                    console.error("获取小区列表", e);
                }
            },
            //小区点击事件
            createMarkerContent(data) {
                //返回弹框样式
                return `<section class="marker-content">
                         <div class="header">${ data.name } ${data.apartmentsCount || 0}套</div>
                         <div class="content flex align-center">
                              <div class="item flex align-center">
                                   <div class="label">已租：</div>
                                   <div class="value">${ data.rentingCount || 0 }套</div>
                              </div>
                              <div class="item flex align-center">
                                   <div class="label">空置：</div>
                                   <div class="value">${ data.vacantCount || 0 }套</div>
                              </div>
                              <div class="item flex align-center">
                                   <div class="label">预约：</div>
                                   <div class="value">${ data.boundCount || 0 }套</div>
                              </div>
                         </div>
                    </section>`;
            }
        },
        mounted() {
            this.getBaseInfoCommunity();
        },
    };
</script>

<style lang="scss" scoped>
     .house-distribution {
          width: 100%;
          height: 100%;

          .gao-de-map {
               width: 100%;
               height: 100%;

               /deep/ .amap-info {
                    .amap-info-content {
                         color: #fff;
                         font-size: rem(14px);
                         padding: VH(15px) VW(15px);
                         border-radius: 10px;
                         background: linear-gradient(180deg, #468EFB 0%, #2B69C8 100%);

                         .header {
                              position: relative;
                              padding-left: VW(15px);

                              &::before {
                                   width: 5px;
                                   height: 100%;
                                   content: '';
                                   background-color: #00C669;
                                   position: absolute;
                                   left: 0;
                              }
                         }

                         .content {
                              font-size: rem(12px);
                              padding-top: VH(15px);

                              .item {
                                   padding-left: VW(15px);

                                   .value {
                                       padding-right: VW(10px);
                                   }
                              }
                         }

                         .amap-info-close {
                              color: #fff;
                         }
                    }
                    .bottom-center .amap-info-sharp {
                         border-top-color: #468EFB;
                    }
               }
          }
     }
</style>
