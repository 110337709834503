<template>
  <section class="individual-contract">
    <div class="top-bar bg-white">
      <div style="margin-bottom: -18px;overflow: hidden;">
        <el-form ref="formSearch" :model="formSearch" label-width="10px" size="small" inline>
          <el-form-item>
            <el-select v-model="formSearch.natureCode" placeholder="请选择合同性质">
              <el-option v-for="item in natureCodeList" :key="item.code" :label="item.codeName"
                         :value="item.code"/>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-select v-model="formSearch.propertyCode" placeholder="请选择合同类型">
              <el-option v-for="item in houseTypes" :key="item.code" :label="item.codeName" :value="item.code"/>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-input v-model="formSearch.contractNum" placeholder="请输入合同编号"/>
          </el-form-item>
          <el-form-item>
            <el-input v-model="formSearch.keyword" placeholder="请输入房源名称、租户姓名、租户手机号"/>
          </el-form-item>
          <!--                    <el-form-item>-->
          <!--                        <el-date-picker v-model="formSearch.dt1" value-format="yyyy-MM-dd" format="yyyy-MM-dd"-->
          <!--                                        placeholder="合同开始时间"/>-->
          <!--                    </el-form-item>-->
          <!--                    <el-form-item>-->
          <!--                        <el-date-picker v-model="formSearch.dt2" value-format="yyyy-MM-dd" format="yyyy-MM-dd"-->
          <!--                                        placeholder="合同结束时间"/>-->
          <!--                    </el-form-item>-->
          <el-form-item>
            <!-- 2023/12/11 陈守亮新增 -->
            <el-date-picker v-model="contractValue" type="daterange" format="yyyy-MM-dd" value-format="yyyy-MM-dd"
                            range-separator="至" start-placeholder="合同开始时间" end-placeholder="合同开始时间"/>
          </el-form-item>
          <el-form-item>
            <!-- 2023/12/11 陈守亮新增 -->
            <el-date-picker v-model="contractend" type="daterange" format="yyyy-MM-dd" value-format="yyyy-MM-dd"
                            range-separator="至" start-placeholder="合同结束时间" end-placeholder="合同结束时间"/>
          </el-form-item>
          <el-form-item>
            <!-- 2023/08/24 张晓瑜新增 -->
            <el-date-picker v-model="SigningDate" type="daterange" format="yyyy-MM-dd" value-format="yyyy-MM-dd"
                            range-separator="至" start-placeholder="签约开始时间" end-placeholder="签约结束时间"/>
          </el-form-item>
          <!-- 2024/02/04 张晓瑜所属区域隐藏 -->
          <!-- <el-form-item>
            <el-select v-model="formSearch.district" clearable filterable allow-create default-first-option
                       placeholder="请选择区域">
              <el-option v-for="(item, index) in options" :key="index" :label="item" :value="item"/>
            </el-select>
          </el-form-item> -->
          <el-form-item>
            <el-date-picker v-model="formSearch.dateMonth" value-format="yyyy-MM" format="yyyy-MM" type="month"
                            placeholder="选择月"/>
          </el-form-item>
          <el-form-item>
            <el-select v-model="formSearch.status" placeholder="请选择合同状态">
              <!-- <el-option label="全部" :value="null"/> -->
              <el-option v-for="item in statusCodeList" :key="item.code" :label="item.codeName" :value="item.code"/>
            </el-select>
          </el-form-item>

          <el-form-item  style="width: 150px" >
            <el-input  style="width: 150px !important;" v-model="formSearch.areaMin" placeholder="请输入最小建筑面积"/>
          </el-form-item>
          <el-form-item >
            <span>至</span>
          </el-form-item>
          <el-form-item  style="width:150px" >
            <el-input  style="width: 150px !important;" v-model="formSearch.areaMax" placeholder="请输入最大建筑面积"/>
          </el-form-item>

          <el-form-item>
            <el-button type="primary" @click="handleSearch">查询</el-button>
            <el-button @click="handleReset">重置</el-button>
            <el-button type="primary" @click="handleContractExport">导出数据</el-button>
          </el-form-item>
        </el-form>
      </div>
      <!--            <div>
                      <el-input size="small" style="width: 150px;margin-right: 10px;" v-model="formSearch.apartmentName" placeholder="请输入房源名称"/>
                      <el-input size="small" style="width: 150px;margin-right: 10px;" v-model="formSearch.leasorName" placeholder="请输入租户姓名"/>
                      <el-input size="small" style="width: 150px;margin-right: 10px;" v-model="formSearch.leasorPhone" placeholder="请输入租户手机号"/>
                       <el-date-picker v-model="formSearch.dt2" value-format="yyyy-MM-dd" format="yyyy-MM-dd" placeholder="退租时间"
                                                  style="width: 195px;margin-right: 10px;" size="small"/>
                      <el-select v-model="formSearch.finished" placeholder="请选择合同状态" style="width: 150px;margin-right: 10px"
                                 size="small">
                          <el-option label="全部" :value="0"/>
                          <el-option label="待生效" :value="1"/>
                          <el-option label="无效" :value="2"/>
                          <el-option label="有效" :value="3"/>
                          <el-option label="作废" :value="4"/>
                      </el-select>
                       <el-date-picker
                           v-model="TerminationDate" type="daterange" format="yyyy-MM-dd" value-format="yyyy-MM-dd"
                           size="small" range-separator="至" start-placeholder="退租开始时间" end-placeholder="退租结束时间"/>
                  </div>-->
    </div>
    <r-e-table class="bg-white" ref="contractTableRef" :dataRequest="getContractTableData" :columns="tableColumn"
               :query="formSearch" :height="760" :notAuto="true">
      <template slot="empty">
        <el-empty/>
      </template>
    </r-e-table>

    <!--<dialog-check-out ref="dialogCheckOut" :contractInfo="contractInfo" />-->
  </section>
</template>

<script>
import {getAddressList} from "@/api/community-management";
import {tableColumn} from "@/views/rental-management/individual-contract/data";
import {
  getContracts,
  getContractsCheckoutData,
  contractExport,
  getFlowUrl,
  downloadContract,
  getSigningStatus,
  getNuoNuoContractStatusApi
} from "@/api/contract";
import {downloadByData, base64ToBlobByPDF} from "@/utils/hooks/download";
import {MessageWarning} from "@custom/message";
// import dialogCheckOut from "@/views/rental-management/individual-contract/components/dialog-check-out";
import {timeFormat} from "@custom/index";

export default {
  name: "individual-contract",
  components: {
    // dialogCheckOut
  },
  data() {
    return {
      options: [], //所属区域下拉数据存放
      tableColumn: tableColumn(this),
      formSearch: {
        dt1: "",
        dt2: "",
        natureCode: "",
        contractNum: "",
        dateMonth: "",
        keyword: null,
        propertyCode: null,
        status: null,
        areaMin: "", // 最小建筑面积
        areaMax: "", // 最大建筑面积
        signDt1: "", // 签约开始时间
        signDt2: "", // 签约结束时间
        checkDt1: "", // 退租结束时间
        checkDt2: "", // 退租结束时间
      },
      contractInfo: {},
      natureCodeList: [],
      houseTypes: [],
      rules: {
        dt: [{required: true, message: "请选择日期", trigger: "change"}],
      },
      statusCodeList: [],
      SigningDate: [], // 签约时间
      contractValue:[],//合同时间
      contractend:[],//合同结束时间
      TerminationDate: [], // 退租时间
      loadingOptions: {
        lock: true,
        text: "Loading...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)"
      },
    };
  },
  methods: {
    getContractTableData(params) {
      return getContracts(params);
    },
    handleSearch() {
      this.$refs["contractTableRef"].getTableData();
    },
    handleReset() {
      this.formSearch = {};
      this.SigningDate = [];
      this.TerminationDate = [];
      Promise.resolve(this.formSearch).then(() => this.handleSearch());
    },
    /*async openCheckOut(row) {
                 const loading = this.$loading({lock: true, text: "请您稍后正在打开...", spinner: "el-icon-loading", background: "rgba(0, 0, 0, 0.7)"});
                 //2022/03/21 王江毅 请求接口获取退房信息
                 let {info} = await getContractsCheckoutData(row.uuid);
                 //拿出terminatedDate字段
                 let {terminatedDate} = info;
                 //判断terminatedDate为不为空 为空赋初始值
                 terminatedDate = terminatedDate == null ? new Date() : terminatedDate;
                 info.terminatedDate = terminatedDate;
                 this.contractInfo = info;
                 this.$refs.dialogCheckOut.dialogVisible = true;
                 loading.close();
            },*/
    handleContractExport() {
      const that = this;
      const loadingOptions = that.loadingOptions;
      let loading = that.$loading({...loadingOptions});
      const pageSize = this.$refs["contractTableRef"].pSize;
      const pageNumber = this.$refs["contractTableRef"].pNumber;
      let {
        dt1, dt2, finished, dateMonth, keyword, propertyCode, status, areaMin, areaMax, signDt1, signDt2,
        checkDt1, checkDt2,
      } = this.formSearch;
      dt1 = dt1 ? dt1 : "";
      dt2 = dt2 ? dt2 : "";
      const paramData = {
        dt1, dt2, pageSize, page: pageNumber, finished, dateMonth, keyword, propertyCode, status, areaMin,
        areaMax, signDt1, signDt2, checkDt1, checkDt2,
      };
      contractExport(paramData).then((res) => {
        downloadByData({data: res, filename: `合同${timeFormat(new Date(), "yyyy-MM-dd-HH-mm-ss")}.xlsx`,});
      }).finally(() => loading.close());
    },

    lookDianZiHeTong(uuid, electContractType) {
      // alert(electContractType)
      if (electContractType === 1) {
        this.getFlowUrl(uuid)
      }
      if (electContractType === 2) {
        this.getNuoNuoFlowUrl(uuid);
      }
    },

    getFlowUrl(uuid) {
      const that = this;
      const loadingOptions = that.loadingOptions;
      let loading = that.$loading({...loadingOptions});
      getFlowUrl(uuid).then((res) => {
        let {msg} = res;
        let tempwindow = window.open("", "_blank");
        tempwindow.location = msg;
      }).finally(() => loading.close());
    },

    getNuoNuoFlowUrl(uuid) {
      const that = this;
      const loadingOptions = that.loadingOptions;
      let loading = that.$loading({...loadingOptions});
      getNuoNuoContractStatusApi({contractUuid: uuid, uuid}).then(({info = {}}) => {
        let {contractStatus = null} = info;
        if (contractStatus === null || contractStatus === 1) {
          MessageWarning("当前电子合同未完善")
        } else {
          loading = that.$loading({...loadingOptions});
          downloadContract(uuid).then(res => {
            let {msg} = res;
            if (msg) {
              let blob = base64ToBlobByPDF(msg);
              let pdfUrl = window.URL.createObjectURL(blob);
              let tempwindow = window.open("", '_blank');
              tempwindow.location = pdfUrl;
            }
          }).finally(() => loading.close());
        }
      }).finally(() => loading.close());
    },
  },
  async mounted() {
    let {params: {data},} = this.$route;
    if (data) {
      const {contractNum} = data;
      if (contractNum) this.formSearch.contractNum = contractNum;
    }

    //选择区域接口请求
    let res = await getAddressList();
    this.options = res.list;
    this.houseTypes = await this.$store.dispatch("app/getDictionaryByCodeActions", 600210);
    let list = await this.$store.dispatch("app/getDictionaryByCodeActions", 600220);
    this.houseTypes = this.houseTypes.concat(list);

    this.natureCodeList = await this.$store.dispatch("app/getDictionaryByCodeActions", "500600");
    this.statusCodeList = await this.$store.dispatch("app/getDictionaryByCodeActions", "500700");
    this.statusCodeList = this.statusCodeList.filter(({code}) => code !== 500707 && code !== 500708);
    // this.statusCodeList[0].codeName = '有效合同';
    this.handleSearch();
  },
  watch: {
    // 签约时间
    SigningDate(value) {
      if (value && value.length !== 0) {
        let [signDt1, signDt2] = value;
        this.formSearch.signDt1 = signDt1;
        this.formSearch.signDt2 = signDt2;
      } else {
        this.formSearch.signDt1 = null;
        this.formSearch.signDt2 = null;
      }
    },
    contractValue(value){
      if (value && value.length !== 0) {
        let [signDt1, signDt2] = value;
        this.formSearch.dt1 = signDt1;
        this.formSearch.dt2 = signDt2;
      } else {
        this.formSearch.dt1 = null;
        this.formSearch.dt2 = null;
      }
    },
    contractend(value){
      if (value && value.length !== 0) {
        let [signDt1, signDt2] = value;
        this.formSearch.dt3 = signDt1;
        this.formSearch.dt4 = signDt2;
      } else {
        this.formSearch.dt3 = null;
        this.formSearch.dt4 = null;
      }
    },
    // 退租时间
    TerminationDate(value) {
      if (value && value.length !== 0) {
        let [checkDt1, checkDt2] = value;
        this.formSearch.checkDt1 = checkDt1;
        this.formSearch.checkDt2 = checkDt2;
      } else {
        this.formSearch.checkDt1 = null;
        this.formSearch.checkDt2 = null;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.individual-contract {
  padding: VH(15px) VW(15px);

  .top-bar {
    display: flex;
    margin: VH(10px) 0 VH(25px);
    padding: VH(15px) VW(15px);
    justify-content: center;

    .el-radio-button.is-active {
      box-shadow: 0 0 10px #ddd inset;
    }
  }

  /deep/ .r-e-table {
    .el-table .cell {
      //white-space: nowrap;
      text-align: center;
    }
  }

  /deep/ .el-input{
    width: 200px !important;
  }
}
</style>
